'use strict';
import React from 'react';
import {Link} from 'react-router';
import { browserHistory } from 'react-router';
import SideMenu from '../sideMenu/SideMenu';
import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function(){
    return DashboardComponentRender.call(this);
}

var DashboardComponentRender = function(){
    return (
        <div>
            <SideMenu basePath="/home/list" />
            <div className="main-panel main-panel-color reset-overflow-for-print">
                <Header />
                <div className="content reset-overflow-for-print">
                    <div className="container-fluid">
                        <div className="row">
                            {this.props.children}
                        </div>
                    </div>
                </div> 
                <Footer />
            </div>
        </div>
    )
};




