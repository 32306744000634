import React, { Component } from "react";
import moment from "moment";

// Modules
import api from "../../common/api";

// Components
import AddProperty from "./AddProperty";
import withNotifications from "../withNotifications";

const metadataDefaults = {
  formattedAddress: "",
  location: null,
  placeId: ""
};

const propertyDefaults = {
  address: "",
  baths: "",
  beds: "",
  buyPrice: "",
  cars: "",
  engagements: [],
  phase: "",
  price: "",
  state: "",
  suburb: "",
  post_code: "",
  url: ""
};

function fetchEngagementOptions() {
  const query = encodeURIComponent(
    JSON.stringify({ selectEngagement: "associates" })
  );

  return api
    .get(`/api/clientPurchases/list?filter=${query}`)
    .then(engagements =>
      engagements.purchases.map(engagement => ({
        value: engagement._id,
        label: engagement.labelForScrapper
      }))
    )
    .catch(console.error);
}

class AddPropertyContainer extends Component {
  state = {
    engagementsOptions: [],
    isOverridingAddress: false,
    metadata: { ...metadataDefaults },
    property: { ...propertyDefaults }
  };

  componentDidMount() {
    window.addEventListener("message_received", () => console.log("done"))
    fetchEngagementOptions().then(engagementsOptions =>
      this.setState({ engagementsOptions })
    );
  }

  handleOverrideToggle = () => {
    this.setState(prevState => {
      let metadata = { ...prevState.metadata };
      const property = { ...prevState.property };

      // If we're toggling override off, clear address and metadata
      if (!prevState.isOverridingAddress) {
        metadata = { ...metadataDefaults };
        property.address = "";
      }

      return {
        metadata,
        property,
        isOverridingAddress: !prevState.isOverridingAddress
      };
    });
  };

  handleInputChange = ev => {
    const { name, value } = ev.target;
    this.setState(prevState => ({
      ...prevState,
      property: { ...prevState.property, [name]: value }
    }));
  };

  handleSelectChange = name => value =>
    this.setState(prevState => ({
      ...prevState,
      property: { ...prevState.property, [name]: value }
    }));

  handleGeosuggestSelect = suggest => {
    console.log(suggest);
    const address = suggest.label;
    const state = suggest.gmaps.address_components.find(x =>
      x.types.some(y => y === "administrative_area_level_1")
    ).short_name;
    const suburb = suggest.gmaps.address_components.find(x =>
      x.types.some(y => y === "locality")
    ).short_name;
    const post_code = suggest.gmaps.address_components.find(x =>
      x.types.some(y => y === "postal_code")
    ).short_name;

    // Metadata
    const formattedAddress = suggest.gmaps.formatted_address;
    const location = { ...suggest.location };
    const placeId = suggest.placeId;

    console.log(`postCode ${post_code}`);

    this.setState(prevState => ({
      property: {
        ...prevState.property,
        address,
        state,
        suburb,
        post_code
      },
      metadata: {
        formattedAddress,
        location,
        placeId
      }
    }));
  };

  handleTheButton = () => {
    // console.log("clicked!");
    // window.postMessage(
    //   { type: "FROM_PAGE", text: "Hello from webpage!" }, "*");

    // This maybe better for the future
    // var editorExtensionId = "gcdhefjgfafcahoklnnkjiccinbleadk";

    // // Make a simple request:
    // chrome.runtime.sendMessage(editorExtensionId, {openUrlInEditor: url},
    //   function(response) {
    //     if (!response.success)
    //       handleError(url);
    //   });
  }

  handleSubmit = () => {
    const { property, metadata } = this.state;

    if (
      property.address === "" ||
      property.buyPrice === "" ||
      property.phase === "" ||
      property.price === "" ||
      property.state === "" ||
      property.suburb === ""
    ) {
      return this.props.showNotification("error", {
        title: "Error!",
        message: "Please complete all required fields."
      });
    }

    const newProperty = {};

    newProperty.address = property.address;
    newProperty.bath_rooms = property.baths;
    newProperty.bed_rooms = property.beds;
    newProperty.buy_price = property.buyPrice;
    newProperty.parking = property.cars;
    newProperty.price = property.price;
    newProperty.property_phase = property.phase;
    newProperty.state = property.state;
    newProperty.suburb = property.suburb;
    newProperty.post_code = property.post_code;
    newProperty.url = property.url;

    // Metadata
    newProperty.formatted_address = metadata.formattedAddress;
    newProperty.location = metadata.location;
    newProperty.place_id = metadata.placeId;

    newProperty.flag = true;
    newProperty.overrideStatus = this.state.isOverridingAddress;

    const engagements = property.engagements.map(option => ({
      clientEngagement: option.value
    }));

    switch (property.phase) {
      case "PIG":
        newProperty.piglist = engagements;
        this.createProperty(newProperty);
        break;

      case "HOT":
        if (engagements.length === 0) {
          return this.props.showNotification("error", {
            title: "Error!",
            message: "Please select an engagement."
          });
        }

        newProperty.hotlist = engagements;
        this.createProperty(newProperty);
        break;

      case "Presented":
        if (engagements.length !== 1) {
          return this.props.showNotification("error", {
            title: "Error!",
            message: "Please select one engagement."
          });
        }

        const engagementId = engagements[0].clientEngagement;
        const query = encodeURIComponent(engagementId);

        newProperty.client = [engagementId];
        newProperty.presented = [engagementId];
        newProperty.presented_property_date = moment().toDate();
        newProperty.timestamp = moment()
          .add(5, "days")
          .toDate();

        api.get(`/api/property/presented?filter=${query}`).then(result => {
          if (result.presented) {
            return this.createProperty(newProperty);
          }

          // Runtime will only continue if the request failed (result.presented is falsy)

          if (result.message) {
            return alert(result.message);
          } else {
            this.props.showNotification("error", {
              title: `Already Assigned ${result.presentedPerClient} Properties!`,

              message: `You have already presented ${result.presentedPerClient} properties to this client.`
            });
          }
        });
        break;
    }
  };

  createProperty(property) {
    const { propertyListBase } = this.props;
    Promise.resolve(api.post("/api/property/add", property))
      .then(result => {
        // Guard
        if (result.notify) {
          return alert("", result.notify);
        } else if (result.phase) {
          return this.props.showNotification("warning", {
            title: "Warning!",
            message: "Property already exists."
          });
        }

        this.props.showNotification("success", {
          title: "Success!",
          message: "Added Successfully."
        });

        this.setState(
          {
            isOverridingAddress: false,
            metadata: { ...metadataDefaults },
            property: { ...propertyDefaults }
          },
          () => {
            propertyListBase.handleAssignedOrQueuedScreens(() => {
              propertyListBase.updateStagedFiltersFromQueryParams();
              propertyListBase.fetchProperties();
            });
          }
        );
        const { address, _id: propertyId, suburb, post_code } = result.property;
        const AUSTRALIA_REGEX = /,?\s*Australia\s*/i;
        const cagrPropertyAddress = address.replace(AUSTRALIA_REGEX, "")
        const { lat, lng } = result.property.location;
        
        // addQuery({ cagrPropertyAddress: address.replace(AUSTRALIA_REGEX, ""), suburb, lat, lng, propertyId, serverURL: process.env.SERVER_URL, type: "getDSRData" }, true);
        window.postMessage(
          {
            type: "getPropertyData",
            cagrPropertyAddress: cagrPropertyAddress,
            suburb,
            post_code,
            lat,
            lng,
            propertyId,
            serverURL: process.env.SERVER_URL,
            dsr: "getDSRData"
          }, "*");
      })
      .catch(error => {
        if (error.responseJSON.message) {
          this.props.showNotification("error", {
            title: "Error!",
            message: error.responseJSON.message
          });
        }
      });
  }

  render() {
    const { onClose } = this.props;
    const { engagementsOptions, isOverridingAddress, property } = this.state;

    return (
      <AddProperty
        {...property}
        engagementsOptions={engagementsOptions}
        isOverridingAddress={isOverridingAddress}
        onClose={onClose}
        onGeosuggestSuggestSelect={this.handleGeosuggestSelect}
        onInputChange={this.handleInputChange}
        onOverrideAddressToggle={this.handleOverrideToggle}
        onSelectChange={this.handleSelectChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withNotifications(AddPropertyContainer);
