import _ from "lodash";
import React, { PureComponent } from "react";
import store from "../../redux/store";

// Components
import {
  EngagementSourceRadioButtons,
  FormCardHeader,
  FormInput,
  FormReadOnly,
  ServiceSelect
} from "../FormComponents";

function getEngagementSourceDisplayValue(engagementSource) {
  switch (engagementSource) {
    case "ownNetwork":
      return "Own Network";

    case "CPS":
    case "BA":
      return engagementSource;

    default:
      throw Error(
        "Engagement source does not match known list of engagement sources"
      );
  }
}

class Completed extends PureComponent {
  render() {
    const {
      client,
      engagementSource,
      fee,
      numberOfPeopleHelped,
      onInputChange,
      service,
      excludedFromWatermark,
      reason
    } = this.props;
    const { user } = store.getState();


    return (
      <div>
        <FormCardHeader>Engagement Infomation</FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          <FormReadOnly label="Client Type" value={client.type || ""} />

          <EngagementSourceRadioButtons
            checked={engagementSource}
            name="engagementSource"
            onChange={onInputChange}
          />
          { _.get(user, "info.user_role") === "manager" && (
            <FormInput
              label="Reason Engagement Excluded from Watermark"
              name="reason"
              onChange={onInputChange}
              placeholder="Reason Engagement Excluded from Watermark"
              type="excludedfromWatermark"
              value={fee}
              excludedFromWatermark={excludedFromWatermark}
              reason={reason}
            />
          )}

          <FormInput
            label="Number of People Helped"
            name="numberOfPeopleHelped"
            onChange={onInputChange}
            placeholder="Number Of People Helped"
            type="text"
            value={numberOfPeopleHelped}
          />

          <ServiceSelect
            name="service"
            onChange={onInputChange}
            value={service}
          />

          <FormInput
            label="Fee"
            name="fee"
            onChange={onInputChange}
            placeholder="$9,999"
            type="text"
            value={fee}
          />
        </div>
      </div>
    );
  }
}

export default Completed;
