import Promise from "bluebird";
import { browserHistory } from "react-router";
import _ from "lodash";

const SERVICE_TEMPORARILY_UNAVAILABLE_MESSAGE =
  "The service is temporarily unavailable. Please try again later.";
const INVALID_TOKEN_MESSAGE = "Security token invalid, please login again.";
const EXPIRED_TOKEN_MESSAGE = "Security token expired, please login again.";
const NOT_LOGGED_IN_MESSAGE =
  "Sorry but we could not find your security token, please login again.";

function resetUserLogin() {
  localStorage.removeItem("USER_TOKEN");
  browserHistory.push("/login");
}

const debouncedAlert = _.debounce(alert, 5000, {
  leading: true,
  trailing: false
});

function requestWeb(type, url, data, noTimeout = false) {
  const token = localStorage.getItem("USER_TOKEN");

  const options = {
    type,
    headers: token ? { "x-access-token": token } : null,
    dataType: "json",
    crossDomain: true,
    timeout: noTimeout ? 0 : 15000,
    xhrFields: { withCredentials: true }
  };

  // TODO check if the app calls the API with FormData. If not, we can remove
  // this conditional logic
  // This was using "==" rather than "==="
  if (data && data.constructor === FormData) {
    options.data = data;
    options.contentType = false;
    options.processData = false;
  } else {
    options.data = data ? JSON.stringify(data) : null;
    options.contentType = "application/json";
  }

  return new Promise((resolve, reject) => {
    $.ajax(process.env.SERVER_URL + url.trim(), options)
      .then((data, textStatus, jqXHR) => {
        // Set refreshed token to localStorage
        localStorage.setItem(
          "USER_TOKEN",
          jqXHR.getResponseHeader("x-access-token")
        );
        resolve(data);
      })
      .catch(jqXHR => {
        console.error("Error from API request:", jqXHR);

        // Handle server updating
        if (jqXHR.status === 503) {
          debouncedAlert(SERVICE_TEMPORARILY_UNAVAILABLE_MESSAGE);
          reject(new Error("503 Service Temporarily Unavailable"));
          return;
        }

        // Some error messages are those generated from JWT authentication
        // JWT errors: https://github.com/auth0/node-jsonwebtoken#errors--codes
        switch (jqXHR.responseJSON.message) {
          case "invalid signature":
            debouncedAlert(INVALID_TOKEN_MESSAGE);
            resetUserLogin();
            break;

          case "jwt expired":
            debouncedAlert(EXPIRED_TOKEN_MESSAGE);
            resetUserLogin();
            break;

          // Error message from server/middlewares/auth.js
          case "not logged in":
            debouncedAlert(NOT_LOGGED_IN_MESSAGE);
            resetUserLogin();
            break;

          default:
            debouncedAlert(jqXHR.responseJSON.message);
        }

        reject(new Error(jqXHR.responseJSON));
      });
  });
}

const get = (url, data, noTimeout) => requestWeb("GET", url, data, noTimeout);
const post = (url, data) => requestWeb("POST", url, data);
const put = (url, data) => requestWeb("PUT", url, data);
const _delete = (url, data) => requestWeb("DELETE", url, data);

export default { get, post, put, delete: _delete };
