import React, { Component } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import moment from "moment";

// Modules
import api from "../../common/api";
import { getClientFullName } from "../../common/client-helpers";
import { mapEngagementToRequest, mapResponseToEngagement } from "./utils";

// Components
import AwaitingTenant from "./AwaitingTenant";
import Comments from "./Comments";
import Completed from "./Completed";
import ConditionalContract from "./ConditionalContract";
import PurchaseDetail from "./PurchaseDetail";
import SetToBuyMeetingAndAwaitingBlueprint from "./SetToBuyMeetingAndAwaitingBlueprint";
import withNotifications from "../withNotifications";

class EditClientPurchases extends Component {
  state = {
    engagement: {
      client: {},
      property: null,
      conditions: [],

      // Text
      actualRent: "",
      baId: "",
      budget: "",
      comment: "",
      contractValue: "",
      conveyancer: "",
      exactBudget: "",
      fee: "",
      financeBroker: "",
      growth: "",
      incubationType: "",
      numberOfPeopleHelped: "",
      propertyManager: "",
      realEstateAgency: "",
      realEstateAgent: "",
      rentalEstimate: "",
      service: "",
      state: "",
      status: "",
      yield: "",
      engagementSource: "",
      reason: "",

      // Booleans
      blueprintCompleted: false,
      blueprintSentToClient: false,
      excludedFromWatermark: false,


      // Dates
      blueprintCompletedDate: null,
      blueprintSentToClientDate: null,
      bpDate: null,
      contractDate: null,
      dateAvailableForRent: null,
      datePromised: null,
      engageDate: null,
      financeDate: null,
      g2gDate: null,
      initialDate: null,
      settleDate: null,
      setToBuyDate: null,
      tenantedDate: null,
      financeSatisfied: null,
      bpSatisfied: null,
    }
  };

  growth = null;
  setGrowthRef = ele => (this.growth = ele);

  yield = null;
  setYieldRef = ele => (this.yield = ele);

  componentWillMount() {
    const { purchase: filter } = this.props.params;
    const query = encodeURIComponent(JSON.stringify({ _id: filter }));

    api
      .get(`/api/clientPurchases/list?filter=${query}`)
      .then(res => {
        const engagement = res.purchases[0];
        this.setState({ engagement: mapResponseToEngagement(engagement) },()=>{
        });
        return null;
      })
      .catch(console.error);
  }

  submitEngagementUpdate = () => {
    const { engagement } = this.state;

    api
      .put("/api/clientPurchases/", {
        filter: this.props.params.purchase,
        fields: mapEngagementToRequest(engagement)
      })
      .then(() => {
        this.props.showNotification("success", {
          title: "Success",
          message: "Engagment successfully updated."
        });

        return null;
      })
      .catch(console.error);
  };

  // ========================================================================
  // Input handlers
  // ========================================================================

  handleInputChange = ev => {
    const { name, value, checked } = ev.target;
    if (name === "excludedFromWatermark") {
      this.setState(prevState => ({
        engagement: {
          ...prevState.engagement,
          [name]: checked
        }
      }));
    } else {
      this.setState(prevState => ({
        engagement: {
          ...prevState.engagement,
          [name]: value
        }
      }));
    }
  };

  handleDateChange = name => date => {
    console.log(`name ${name}`);
    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        [name]: date
      }
    }));
  };



  handleCheckBoxChange = ev => { 
    const { name, checked } = ev.target;
    console.log(`checked ${checked}`);
    console.log(`name ${name}`);
    console.log (checked ? new Date(): null);
    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        [name]: checked ? new Date().toString() : null
      }
    }));
  };


  handleInputNaChange = name => () => {
    this[name].disabled = !this[name].disabled;

    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        [name]: ""
      }
    }));
  };

  handleBlueprintCheckboxChange = ev => {
    const { name, checked } = ev.target;
    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        [name]: checked,
        [name + "Date"]: checked ? moment() : null
      }
    }));
  };

  // ========================================================================
  // Condition table
  // ========================================================================

  handleCreateCondition = () => {
    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        conditions: prevState.engagement.conditions.concat({
          condition_date: null,
          condition: "",
          satisfied: null
        })
      }
    }));
  };

  handleRemoveCondition = index => {
    this.setState(prevState => ({
      engagement: {
        ...prevState.engagement,
        conditions: prevState.engagement.conditions.filter(
          (x, i) => i !== index
        )
      }
    }));
  };

  handleConditionChange = (index, name, value) => {
    this.setState(prevState => {
      const { conditions: prevConditions } = prevState.engagement;

      // Perform immutable update to conditions
      const newConditions = [
        ...prevConditions.slice(0, index),
        { ...prevConditions[index], [name]: value },
        ...prevConditions.slice(index + 1)
      ];

      return {
        engagement: {
          ...prevState.engagement,
          conditions: newConditions
        }
      };
    });
  };

  // ========================================================================
  // Render
  // ========================================================================

  render() {
    const { engagement } = this.state;

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              <h2 style={{ fontFamily: '"Poppins", sans-serif' }}>
                {getClientFullName(engagement.client)} Engagement Details
              </h2>
            </div>

            <div className="col-md-2">
              <i
                className="ti-back-left right back_button"
                onClick={() => {
                  browserHistory.goBack();
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card" style={{ zIndex: "initial" }}>
                <PurchaseDetail
                  baId={engagement.baId}
                  bas={this.props.buyers}
                  budget={engagement.budget}
                  client={engagement.client}
                  engageDate={engagement.engageDate}
                  exactBudget={engagement.exactBudget}
                  financeBroker={engagement.financeBroker}
                  g2gDate={engagement.g2gDate}
                  growth={engagement.growth}
                  growthRef={this.setGrowthRef}
                  incubationType={engagement.incubationType}
                  initialDate={engagement.initialDate}
                  onDateChange={this.handleDateChange}
                  onInputChange={this.handleInputChange}
                  onInputNaChange={this.handleInputNaChange}
                  property={engagement.property}
                  state={engagement.state}
                  status={engagement.status}
                  yield={engagement.yield}
                  yieldRef={this.setYieldRef}
                />
              </div>

              <div className="card" style={{ zIndex: "initial" }}>
                <AwaitingTenant
                  actualRent={engagement.actualRent}
                  dateAvailableForRent={engagement.dateAvailableForRent}
                  onDateChange={this.handleDateChange}
                  onInputChange={this.handleInputChange}
                  propertyManager={engagement.propertyManager}
                  settleDate={engagement.settleDate}
                  tenantedDate={engagement.tenantedDate}
                />
              </div>

              <div className="card" style={{ zIndex: "initial" }}>
                <Comments
                  comment={engagement.comment}
                  onInputChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="card" style={{ zIndex: "initial" }}>
                <SetToBuyMeetingAndAwaitingBlueprint
                  blueprintCompleted={engagement.blueprintCompleted}
                  blueprintCompletedDate={engagement.blueprintCompletedDate}
                  blueprintSentToClient={engagement.blueprintSentToClient}
                  blueprintSentToClientDate={
                    engagement.blueprintSentToClientDate
                  }
                  datePromised={engagement.datePromised}
                  onBlueprintCheckboxChange={this.handleBlueprintCheckboxChange}
                  onDateChange={this.handleDateChange}
                  setToBuyDate={engagement.setToBuyDate}
                />
              </div>

              <div className="card" style={{ zIndex: "initial" }}>
                <ConditionalContract
                  buildingAndPestDate={engagement.bpDate}
                  bpSatisfied={engagement.bpSatisfied}
                  conditions={engagement.conditions}
                  contractDate={engagement.contractDate}
                  contractValue={engagement.contractValue}
                  conveyancer={engagement.conveyancer}
                  financeDate={engagement.financeDate}
                  financeSatisfied={engagement.financeSatisfied}
                  onConditionChange={this.handleConditionChange}
                  onCreateCondition={this.handleCreateCondition}
                  onDateChange={this.handleDateChange}
                  onInputChange={this.handleInputChange}
                  onRemoveCondition={this.handleRemoveCondition}
                  onCheckBoxChange={this.handleCheckBoxChange}
                  realEstateAgency={engagement.realEstateAgency}
                  realEstateAgent={engagement.realEstateAgent}
                  rentalEstimate={engagement.rentalEstimate}
                  settleDate={engagement.settleDate}
                />
              </div>

              <div className="card" style={{ zIndex: "initial" }}>
                <Completed
                  client={engagement.client}
                  engagementSource={engagement.engagementSource}
                  fee={engagement.fee}
                  numberOfPeopleHelped={engagement.numberOfPeopleHelped}
                  onInputChange={this.handleInputChange}
                  excludedFromWatermark={this.state.engagement.excludedFromWatermark}
                  reason={this.state.engagement.reason}
                  service={engagement.service}
                />
              </div>
            </div>
          </div>

          <div className="row cntr p-a-10">
            <button
              className="btn btn-fill btn-info"
              onClick={this.submitEngagementUpdate}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ buyers: state.buyers || [] });

export default connect(mapStateToProps)(withNotifications(EditClientPurchases));
